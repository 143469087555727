<!--
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-05 16:44:54
 * @LastEditTime: 2020-07-02 15:07:32
 * @Description: 规格搜索条
 -->
<template>
  <div>
    <a-form class="filter-condition-form" layout="inline" :form="form" @submit="handleFormSubmit">
      <a-form-item>
        <a-input
          v-model.trim="searchParams.search"
          allowClear
          placeholder="规格名称"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" :loading="loading">查询</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>

export default {
  name: "SpecSearchBar",
  props: {
    searchParams: {
      default: function() {
        return {};
      },
      type: Object
    },
    loading: {
        default: false,
        type: Boolean
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
    };
  },
  methods: {
    // 查询专题submit
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.$emit('callback', this.searchParams)
        }
      });
    }
  }
};
</script>
<style lang='less' scoped>
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
</style>
