/*
 * @Description: 寄样相关接口
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-07 16:16:34
 * @LastEditTime: 2020-10-14 20:11:55
 */
import axios from './axios'

// 用户意见反馈列表
export const getFeedbackList = params => {
  return axios.get('/api/operators/v1/advice/', {
    params
  })
}

// 折一自定义模块列表
export const getCustomModule = params => {
  return axios.get('/api/operators/v1/custom-module/', { params })
}

// 更新折一自定义模块
export const patchCustomModule = data => {
  return axios.patch('/api/operators/v1/custom-module/', data)
}

// 后台查询折一权益
export const getKuranWelfare = () => {
  return axios.get('/api/operators/v1/kuran-welfare/')
}

// 后台设置折一权益
export const patchKuranWelfare = data => {
  return axios.patch('/api/operators/v1/kuran-welfare/', data)
}

// 规格列表
export const getSpecs = params => {
  return axios.get('/api/operators/v1/specs/', { params })
}

// 新增规格
export const postSpec = data => {
  return axios.post('/api/operators/v1/specs/', data)
}

// 删除规格
export const delSpec = data => {
  return axios.post('/api/operators/v1/delete-specs/', data)
}


// 品牌列表
export const brandList = params => {
  return axios.get('/api/operators/v1/brand/', { params })
}

// 新增品牌
export const addBrand = data => {
  return axios.post('/api/operators/v1/brand/', data)
}

// 更新品牌
export const editBrand = (id, data) => {
  return axios.patch(`/api/operators/v1/brand/${id}/`, data)
}


// 抖音小店店铺列表
export const shopList = params => {
  return axios.get('/api/operators/v1/douyin-shop/', { params })
}

// 新增抖音小店店铺
export const addShop = data => {
  return axios.post('/api/operators/v1/douyin-shop/', data)
}

// 更新抖音小店店铺
export const editShop = (id, data) => {
  return axios.patch(`/api/operators/v1/douyin-shop/${id}/`, data)
}

// 专场列表
export const specialList = params => {
  return axios.get('/api/operators/v1/special/', { params })
}

// 新增专场
export const addSpecial = data => {
  return axios.post('/api/operators/v1/special/', data)
}

// 查询品牌专场品牌是否已存在
export const checkSpecialBrand = params => {
  return axios.get('/api/operators/v1/check-special-brand-isuniq/', { params })
}

// 编辑专场
export const editSpecial = (id, data) => {
  return axios.patch(`/api/operators/v1/special/${id}/`, data)
}

// 专场详情
export const specialDetail = (id) => {
  return axios.get(`/api/operators/v1/special/${id}/`)
}

// 启用/禁用专场
export const specialSwitch = (id, data) => {
  return axios.patch(`/api/operators/v1/special-enable-switch/${id}/`, data)
}

// 专场报名列表
export const specialApply = params => {
  return axios.get('/api/operators/v1/special-apply/', { params })
}

// 专场添加商品时校验商品
export const checkSpecial = params => {
  return axios.get('/api/operators/v1/check-goods-on-special/', { params })
}

// 查询专场是否存在
export const checkSpecialExist = id => {
  return axios.get(`/api/operators/v1/specialId/${id}/`)
}

// 校验关联商品
export const checkRelatedGoods = (params) => {
  return axios.get(`/api/operators/v1/check-related-goods/`, { params })
}


// 活动添加
export const campaign_add = params => {
  return axios.post('/api/operators/v1/campaign/list/', params)
}
// 商品里诶包
export const campaign_product_list = params => {
  return axios.get(`/api/operators/v1/campaign/${params.campaign_id}/product/`, { params })
}
// 团长活动商品详情同步
export const campaign_product_sync_list = params => {
  return axios.post(`/api/operators/v1/campaign/${params.campaign_id}/product/sync/`, { params })
}
// 团长活动列表同步
export const campaign_productlist_sync_list = params => {
  return axios.post(`/api/operators/v1/campaign/list/sync/`, { params })
}

// 团长活动列表
export const campaign_list = params => {
  return axios.get('/api/operators/v1/campaign/list/', { params })
}
// 团长活动_商品添加
export const campaign_product_add = params => {
  return axios.post(`/api/operators/v1/campaign/${params.campaign_id}/product/`, params, { throwMessage: false })
}
// 团长活动_商品编辑
export const campaign_product_edit = params => {
  return axios.patch(`/api/operators/v1/campaign/${params.campaign_id}/product/`, params)
}
// 团长活动_商品删除
export const campaign_product_delete = params => {
  return axios.delete(`/api/operators/v1/campaign/${params.campaign_id}/product/`, { params })
}
// 团长活动_商品上架下架
export const campaign_update_goods_sale = params => {
  return axios.patch('/api/operators/v1/batch-update-kuran-goods-sale-status/ ', params)
}
// 团长活动_批量商品上架下架
export const campaign_tatch_update_goods_sale = params => {
  return axios.patch('/api/operators/v1/campaign/batch_update_sale_status/', params)
}
// 团长活动_获取商品详情
export const campaign_goods_info = params => {
  return axios.get('/api/operators/v1/kuran-goods/', { params })
}
//活动商品导出
// 导出折一商品
export const exportKrGoodsList = params => {
  return axios.get(`/api/operators/v1/export-kuran-goods/`, {
    params
  })
}
