<!--
 * @Description: 规格管理modal
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-12 17:03:34
 * @LastEditTime: 2020-09-15 11:49:50
 -->
 <template>
  <div>
    <a-modal
      class="batch-upload-express-dialog"
      :title="modalTitle"
      v-model="isShowModal"
      centered
      :bodyStyle="{padding:0}"
      :confirm-loading="confirmLoading"
      @ok="handleSubmit"
      @cancel="hideModal"
      okText="确认保存"
    >
      <div class="p-20">
        <a-form-model ref="specForm" :model="formData" :rules="rules" @submit="handleSubmit">
          <a-form-model-item label="规格名称" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" prop="name" v-if="data.create_type === 1">
            <a-input v-model="formData.name" style="width:300px" :maxLength="10" placeholder="请输入规格名称" />
          </a-form-model-item>
          <a-form-model-item label="规格值" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" prop="value">
            <a-input v-model="formData.value" style="width:300px" :maxLength="10" placeholder="请输入规格名称" />
            <div v-if="data.create_type === 2">
              <a-tag v-for="i in data.value_list" :key="i.id">{{i.value}}</a-tag>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { postSpec } from "@/service/operate";
export default {
  name: "ModalSpec",
  props: {
    data: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      isShowModal: true,
      confirmLoading: false,
      rules: {
        name: [{ required: true, message: "请输入规格名称", trigger: "blur" }],
        value: [{ required: true, message: "请输入规格值", trigger: "blur" }]
      },
      formData: {
        name: this.data.name || "",
        value: this.data.value || ""
      }
    };
  },
  computed: {
    // 性别
    modalTitle () {
      return this.data.create_type === 1 ? '新建规格' : '编辑规格值'
    }
  },
  methods: {
    // 隐藏批量新增弹层
    hideModal(isNeedRefresh = -1) {
      this.$emit("callback", isNeedRefresh);
    },
    // 保存数据
    async handleSubmit(e) {
      e.preventDefault();
      this.$refs.specForm.validate(async valid => {
        if (valid === true) {
          const data = {
            name: this.formData.name,
            value: this.formData.value,
            create_type: this.data.create_type 
          };

          this.confirmLoading = true;
          const { err, res } = await postSpec(data);
          this.confirmLoading = false;
          if (!err && res.success) {
            this.hideModal(0);
          }
        }
      });
    }
  }
};
</script>
