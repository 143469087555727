<!--
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-03 17:16:48
 * @LastEditTime: 2020-07-14 16:11:23
 * @Description: 规格管理
 -->
<template>
  <div id="audit">
    <div class="tab-content">
      <SpecSearchBar v-auth="'basic_specs_list'" @callback="searchBarCallback" :searchParams="searchParams" :loading="listLoading" />
      <div class="option-btns">
        <a-button v-auth="'basic_specs_add'" type="primary" class="option-btn" html-type="submit" @click="handleShowCreateSpecModal()">添加规格</a-button>
      </div>
      <a-table :scroll="{ y: tableScrollY }" v-auth="'basic_specs_list'" :rowKey="record => record.item_id" :columns="specColumns" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ emptyText: '暂无规格' }" @change="handleTableChange">
        <template slot="valueItems" slot-scope="record">
          <a-tag v-for="v in record.value_list" :key="v.id">{{v.value}}</a-tag>
        </template>
        <template slot="operation" slot-scope="record">
          <a-button size="small" class="mr-10" @click="handleEditSpec(record)" v-auth="'basic_specs_modify'">编辑</a-button>
          <a-button type="primary" size="small" @click="handleDelConfirm(record)" v-auth="'basic_specs_modify'">删除</a-button>
        </template>
      </a-table>
    </div>
    <!--新建规格-->
    <ModalSpec v-if="isShowCreateSpec" :data="currentSpec" @callback="handleHideCreateSpecModal" />
    <!--修改规格-->
    <ModalEditSpec v-if="isShowEditSpec" :data="currentSpec" @callback="handleHideCreateSpecModal" />
  </div>
</template>

<script>
import { getSpecs, delSpec } from "@/service/operate";
import SpecSearchBar from "@/components/admin/baseinfo/spec-search-bar";
import ModalSpec from "@/components/admin/baseinfo/modal-spec";
import { checkPer } from "@/utils/index";

// 规格列表
const specColumns = [
  {
    title: "规格名称",
    dataIndex: "name",
    width: 140
  },
  {
    title: "规格值",
    scopedSlots: { customRender: "valueItems" }
  },

  {
    title: "操作",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  name: "GoodsList",
  components: {
    SpecSearchBar,
    ModalSpec
  },
  data() {
    return {
      tableScrollY: 600,
      form: this.$form.createForm(this),
      specColumns,
      isShowCreateSpec: false,
      isShowEditSpec: false,
      currentGoodsChannel: 1,
      listLoading: true,
      list: [],
      currentSpec: null,
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      // 查询条件
      searchParams: {
        search: ""
      }
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 355;
  },
  mounted() {
    this.getSpecList();
  },
  methods: {
    // 新增规格modal
    handleShowCreateSpecModal() {
      this.currentSpec = { create_type: 1 };
      this.isShowCreateSpec = true;
    },
    // 隐藏规格modal
    handleHideCreateSpecModal(freshPage) {
      this.isShowCreateSpec = false;
      if (freshPage === 0) {
        // 刷新当前页
        this.getSpecList();
      } else if (freshPage === 1) {
        // 刷新第一页
        this.pagination.current = 1;
        this.getSpecList();
      }
    },
    // 获取规格列表
    async getSpecList() {
      if (!checkPer("basic_specs_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      const data = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        search: this.searchParams.search
      };
      this.listLoading = true;
      const { err, res } = await getSpecs(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getSpecList();
    },
    // 点击编辑按钮
    handleEditSpec(record) {
      const postData = JSON.parse(JSON.stringify(record));
      postData.create_type = 2;
      this.currentSpec = postData;
      this.isShowCreateSpec = true;
    },
    // 删除确认
    handleDelConfirm(record) {
      const that = this;
      this.$confirm({
        title: "删除规格",
        content: () => (
          <div>
            删除规格会删除所有对应的规格值，确认删除{" "}
            <span style="color:red;">{record.name}</span> ?
          </div>
        ),
        okText: "确认删除",
        async onOk() {
          const { err, res } = await delSpec({ name: record.name });
          if (!err) {
            if (res.success) {
              that.$message.success(res.message);
              that.pagination.current = 1;
              that.getSpecList();
            }
          }
        },
        onCancel() {}
      });
    },
    // 查询
    searchBarCallback(e){
      this.searchParams.search = e.search;
      this.pagination.current = 1;
      this.getSpecList()
    }
  }
};
</script>
<style lang='less' scoped>
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
.upload-res-box {
  line-height: 150%;
}
.upload-spin {
  text-align: center;
  padding: 30px 50px;
}
.p-20 {
  padding: 20px;
}
.mr-10 {
  margin-right: 10px;
}
</style>
